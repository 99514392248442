// react  components
import { useState, useEffect, useRef } from "react";
// styles
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot, 
         faMapLocationDot,
         faCalendarDays,
         faUser,
         faCreditCard, 
         faMobileScreenButton,
         faEnvelope,} from '@fortawesome/free-solid-svg-icons'

import { faSquareFacebook,
         faInstagram,
         faYoutube} from '@fortawesome/free-brands-svg-icons'


import slovakia from './assets/images/slovakia.png'
import openday from './assets/images/den-otvorenych-dveri.jpg'
import western from './assets/images/western.jpg'
import circus from './assets/images/circus.jpg'
import sherlock from './assets/images/sherlock.jpg'
import photo_1 from './assets/images/1.jpg'
import photo_2 from './assets/images/2.jpg'
import photo_3 from './assets/images/3.jpg'
import photo_4 from './assets/images/4.jpg'
import photo_5 from './assets/images/5.jpg'
import photo_6 from './assets/images/6.jpg'
import photo_7 from './assets/images/7.jpg'
import photo_8 from './assets/images/8.jpg'
import photo_9 from './assets/images/9.jpg'
import photo_10 from './assets/images/10.jpg'
import photo_11 from './assets/images/11.jpg'
import photo_12 from './assets/images/12.jpg'

import activity from './assets/images/activity.jpg'
import crafty from './assets/images/crafty.jpg'
import vecer from './assets/images/vecer.jpg'
import taborak from './assets/images/taborak.jpg'
import fyzicke from './assets/images/fyzicke.jpg'
import duchovne from './assets/images/duch.jpg'
import skupinky from './assets/images/skupinky.jpg'
import kaplnka from './assets/images/kaplnka.jpg'
import bufet from './assets/images/bufet.jpg'
import dobrovolnictvo from './assets/images/dobrovolnictvo.jpg'
import special from './assets/images/special.jpg'


export default function App() {

  const [formDataCampers, setFormDataCampers] = useState({
    first_name: '',
    surname: '',
    address: '',
    city: '',
    email: '',
    birthdate: '',
    number: '',
    number_parent: '',
    message: '',
    tshirt: 'XS', // Predvolená veľkosť trička
    expectations: '',
    first_time: 'nie' // Predvolená hodnota
  });

  const [formDataServant, setFormDataServant] = useState({
    type: 'Programový tím',
    first_name: '',
    surname: '',
    address: '',
    city: '',
    email: '',
    birthdate: '',
    number: '',
    number_parent: '',
    message: '',
    tshirt: 'XS', // Predvolená veľkosť trička
    expectations: '',
    first_time: 'nie' // Predvolená hodnota
  });

  const [isRegistered, setisRegistered] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleChangeCampers = (e) => {
    const { name, value } = e.target;
    setFormDataCampers(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleChangeServant = (e) => {
    const { name, value } = e.target;
    setFormDataServant(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmitCampers = (e) => {
    e.preventDefault();
    var url = "https://megakemper.sk/backend/add_campers.php";
    var headers = {
      'accept' : 'application/json',
      'Content-Type': 'application/json'
    }
    
    fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(formDataCampers)
    })
    .then(response => response.json())
    .then(response => {
      if(response[0].error) {
        setisRegistered(true);
        console.error('Chyba pri spracovaní údajov:', response[0].result);
      } else {
        setIsSuccess(true);
        setisRegistered(true);
      }
    })
    .catch(error => {
      console.error('Chyba pri odosielaní údajov:', error);
    });
  };

  const handleSubmitServant = (e) => {
    e.preventDefault();
    fetch('https://megakemper.sk/backend/add_servant.php', {
      method: 'POST',
      headers: {
        'accept' : 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formDataServant)
    })
    .then(response => response.json())
    .then(response => {
      if(response[0].error) {
        setisRegistered(true);
        console.error('Chyba pri spracovaní údajov:', response[0].result);
      } else {
        setIsSuccess(true);
        setisRegistered(true);
        console.log(response);
      }
    })
    .catch(error => {
      console.error('Chyba pri odosielaní údajov:', error);
    });
  };

  const [isVisibleVideo, setIsVisibleVideo] = useState(false);
  const [isVisibleMap, setIsVisibleMap] = useState(false);
  const [isVisibleInfo, setIsVisibleInfo] = useState(false);
  const [isVisibleAbout, setIsVisibleAbout] = useState(false);
  const [isVisibleOpenDay, setIsVisibleOpenDay] = useState(false);
  const [isVisibleSpecialDay, setIsVisibleSpecialDay] = useState(false);
  const RefVideo = useRef(null);
  const RefMap = useRef(null);
  const RefInfo = useRef(null);
  const RefAbout = useRef(null);
  const RefOpenDay = useRef(null);
  const RefSpecialDay = useRef(null);


  useEffect(() => {
    const handleScroll = () => {

      const RefVideoPosition = RefVideo.current.getBoundingClientRect().top;
      const RefMapPosition = RefMap.current.getBoundingClientRect().top;
      const RefInfoPosition = RefInfo.current.getBoundingClientRect().top;
      const RefAboutPosition = RefAbout.current.getBoundingClientRect().top;
      const RefOpenDayPosition = RefOpenDay.current.getBoundingClientRect().top;
      const RefSpecialDayPosition = RefSpecialDay.current.getBoundingClientRect().top;

      const windowHeight = window.innerHeight - window.innerHeight / 100 * 20;

      if(RefVideoPosition <= windowHeight){if(!isVisibleVideo){setIsVisibleVideo(true)}}
      if(RefMapPosition <= windowHeight){if(!isVisibleMap){setIsVisibleMap(true)}}
      if(RefInfoPosition <= windowHeight){if(!isVisibleInfo){setIsVisibleInfo(true)}}
      if(RefAboutPosition <= windowHeight){if(!isVisibleAbout){setIsVisibleAbout(true)}}
      if(RefOpenDayPosition <= windowHeight){if(!isVisibleOpenDay){setIsVisibleOpenDay(true)}}
      if(RefSpecialDayPosition <= windowHeight){if(!isVisibleSpecialDay){setIsVisibleSpecialDay(true)}}
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [selectedPerson, setSelectedPerson] = useState(1);

  const handleClick = (index) => {
    setSelectedPerson(index);
  };

  const [selectProgram, SetselectProgram] = useState(1);

  const handleProgram = (index) => {
    SetselectProgram(index);
  };


  const [isCamperRegistrationActive, setIsCamperRegistrationActive] = useState(true);
  const [isServantRegistrationActive, setIsServantRegistrationActive] = useState(false);

  const toggleRegistrationType1 = () => {
    setIsCamperRegistrationActive(true);
    setIsServantRegistrationActive(false);
  };
  const toggleRegistrationType2 = () => {
    setIsCamperRegistrationActive(false);
    setIsServantRegistrationActive(true);
  };

  const [showTrailer, setShowTrailer] = useState(false);

  const toggleTrailer = () => {
    setShowTrailer(!showTrailer);
  };

  const calculateTimeLeft = () => {
    const difference = +new Date("2024-08-09T15:00:00") - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <div className="App__box">
      <header className='header__bg'>
        <div className="header__box">
          <div className='nav__box'>
            <p className='nav__title'>Megakemper</p>
            <nav className='Nav'>
              <ul className='nav__list'>
                <li className='nav__list_item'>
                  <a href="#video">video</a>
                </li>
                <li className='nav__list_item'>
                  <a href="#info">info</a>
                </li>
                <li className='nav__list_item'>
                  <a href="#about_us">o nás</a>
                </li>
                <li className='nav__list_item'>
                  <a href="#program">program</a>
                </li>
                <li className='nav__list_item'>
                  <a href="#contact">kontakt</a>
                </li>
                <li className='nav__list_item'>
                  <a href="#registration">registruj sa</a>
                </li>
              </ul>
            </nav>
          </div>
          <div className='header__title_box'>
            {/* <h1 className='header__title'>Tu bude<br/>slogan</h1> */}
            <h1 className='header__title'>Múdrosť nespočíva v tom,<br/> že veci vidíme,<br/> ale že vidíme skrze ne</h1>
          </div>
        </div>
      </header>

      <div className='countdown'>
        <div className='countdown__box'>
          <div className='countdown__item'>
            <div className='countdown__number_box'>
              <p className='countdown__number'>{timeLeft['days']}</p>
            </div>
            <p className='countdown__text'>Dní</p>
          </div>
          <div className='countdown__item'>
            <div className='countdown__number_box'>
              <p className='countdown__number'>{timeLeft['hours']}</p>
            </div>
            <p className='countdown__text'>Hodín</p>
          </div>
          <div className='countdown__item'>
            <div className='countdown__number_box'>
              <p className='countdown__number'>{timeLeft['minutes']}</p>
            </div>
            <p className='countdown__text'>Minút</p>
          </div>
          <div className='countdown__item'>
            <div className='countdown__number_box'>
              <p className='countdown__number'>{timeLeft['seconds']}</p>
            </div>
            <p className='countdown__text'>Sekúnd</p>
          </div>
        </div>
      </div>

      <section id="introduction" className='introduction'>
        <div className='review__box'>
          <div className="review__bg">
            <div 
              className={`
                ${selectedPerson == 1 ? 'review__card_1' : ''}
                ${selectedPerson == 2 ? 'review__card_2' : ''}
                ${selectedPerson == 3 ? 'review__card_3' : ''}
              `}
              >
              {selectedPerson == 1 && (
                <p className='review__card_text'>Megakemper je pre mňa kúzelnych 10 dní, kedy sa na chvíľu zastaví čas. Priatelia, Pán Ježiš a krásne miesto je to, čo vnímam ako vzácnosť. Najviac je pre mňa špeciálny posledný deň, kedy spolu večeriame pri jednom veľkom stole. To ako sa všetci premeníme so špinavých a zablatených ľudí na čistých a krásne oblečených ma v sebe čaro. MEGAKEMPER si preto nikdy nenechám ujsť.</p>
              )}
              {selectedPerson == 2 && (
                <p className='review__card_text'>Na toto miesto sa vraciam každý rok, nádherná atmosféra plná neobyčajného programu, noci strávene pod hviezdami, večerný spev chvál. Šťavnaté hry s veľkou kreativitou a hlavne konferenčná kvalita speakerov a reflektovanie toho čo nám deň dal. Stretol som tu ľudí s veľkým zapálením pre Boha a službu jemu. Vášmu letu by tento tábor nemal určite újsť.</p>
              )}
              {selectedPerson == 3 && (
                <p className='review__card_text'>Megakemper je najlepší tábor na svete. Nie je však pre slabé osôbky, čo sa boja zašpiniť, kúpať v parasprche alebo nájsť útočisko v latrínkach. Ak túto nepríjemnosť prekonáš nájdeš raj. Program smrteľníkov je nadupaný novinkami každý rok. Hlavný program, súťažné aktivity, špeciálne dni. A potom je tu program, na ktorý si trúfnu tý najodvážnejší, fyzické a duchovné výzvi. Budeš čeliť potu, hladu, nedostatku spánku, svojmu vlastnému egu a spevu.? V týchto podmienkach odhalíš kto vlastne si, načo máš a na čo ešte nie. Tak poď. Megakemper ťa volá.</p>
              )}
            </div>
          </div>
          <div className='review__persons_box'>
            <div className='review__person' onClick={() => handleClick(1)}>
              <div className={`review__img img_1 ${selectedPerson == 1 ? 'review_active' : ''}`}></div>
              <p className='review__title'>Soňa Bodnárová</p>
              <p className='review__subtitle'>Táborníčka</p>
            </div>
            <div className='review__person' onClick={() => handleClick(2)}>
              <div className={`review__img img_2 ${selectedPerson == 2 ? 'review_active' : ''}`}></div>
              <p className='review__title'>Gabriel Nagypál</p>
              <p className='review__subtitle'>Fotograf / strihač</p>
            </div>
            <div className='review__person' onClick={() => handleClick(3)}>
              <div className={`review__img img_3 ${selectedPerson == 3 ? 'review_active' : ''}`}></div>
              <p className='review__title'>Ján Krajčík</p>
              <p className='review__subtitle'>Táborník</p>
            </div>
          </div>
        </div>
        <div className='introduction__box'>
          <h2 className='introduction__title'>Príď a zaži!</h2>
          <p className='introduction__text'>Nad zdychavskými lazmi zapadlo slnko. V povetrí sa nesie chladná vôňa lesa. Hviezdy tu žiaria jasnejšie než inde. Sedíš pri vatre, pozeráš do ohňa, a spolu s tónmi gitary a zvukmi praskajúceho dreva sa nechávaš unášať k udalostiam z dnešného dňa. Si unavený, no usmievaš sa, lebo si nevieš predstaviť lepšie strávený čas. Toto je ochutnávka Zdychavy. Momenty, ktoré v živote zažiješ len raz.
          <br/><br/>
          Opúšťanie komfortu po fyzickej aj psychickej stránke, komunita, vzťah k sebe a vzťah s Bohom - dobrodružstvá, do ktorých sa oplatí vykročiť.
          </p>
          <a className='introduction__button' href="#registration">chcem to zažiť</a>
        </div>
      </section>
      
      <section id='video' className='video'>
        <div className='video__bg'>
          {showTrailer && (
            <iframe
              className="video__trailer"
              src="https://www.youtube.com/embed/6UvJjTkYA3o"
              title="Megakemper trailer"
            />
          )}
          <div 
            ref={RefVideo}
            className={`video__content animated ${isVisibleVideo ? ' fadeInLeft' : 'hidden'}`}
          >
            <h2 className='video__title'>Pozri si naše úžasné video!</h2>
            <div className='video__button' onClick={toggleTrailer}></div>
            <a className='video__more' href="https://www.youtube.com/@kostolnahrane4453" target="_blank">Chcem vidieť viac</a>
          </div>
        </div>
      </section>

      <section id='info' className='map'>
        <div className="map__bg">
          <div className="map__gradient">
            <div className="map__box">
              <h2
                ref={RefMap}
                className={`map__title animated ${isVisibleMap ? 'fadeInUp' : 'hidden'}`}
              >Spájame ľudí z celého slovenska</h2>
              <div className={`map__slovakia_box animated ${isVisibleMap ? 'fadeInUp' : 'hidden'}`}>
                <img className="map__slovakia"  src={slovakia}/>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section 
        ref={RefInfo}
        className="info"
      >
        <div className="info__box">
          <div className="info__content">
            <div className="info__flex">
              <div className={`info__item animated ${isVisibleInfo ? 'fadeInUp' : 'hidden'}`}>
                <div className="info__img_bg">
                  <FontAwesomeIcon 
                    icon={faLocationDot} 
                    className="info__img"
                  />
                </div>
                <h3 className="info__title">Kde?</h3>
                <p className="info__desc">
                  <strong>Muránska Zdychava<br/>(Chata BJB)</strong>
                  <br/><br/>
                  Približne 2km za obcou Muránska Zdychava, chata za potôčikom napravo
                </p>
                <a className="info__link" target="_blank" href="https://maps.app.goo.gl/uQvP6KZG96SH2trS9">
                  <FontAwesomeIcon 
                    icon={faMapLocationDot}
                    className="info__link_img"
                  />
                  <p className="info__link_text">Otvoriť mapu</p>
                </a>
              </div>
            
              <div className={`info__item animated ${isVisibleInfo ? 'delay-50ms fadeInUp' : 'hidden'}`}>
                <div className="info__img_bg">
                  <FontAwesomeIcon 
                    icon={faCalendarDays} 
                    className="info__img"
                  />
                </div>
                <h3 className="info__title">Kedy?</h3>
                <p className="info__desc">
                  <strong>9. - 17. august 2024</strong>
                  <br/><br/>
                  Začíname 9.8. o 15:00<br/>
                  Končíme 17.8. o 13:00
                </p>
              </div>
            </div>

            <div className="info__flex">
              <div className={`info__item animated ${isVisibleInfo ? 'delay-100ms fadeInUp' : 'hidden'}`}>
                <div className="info__img_bg">
                  <FontAwesomeIcon 
                    icon={faUser} 
                    className="info__img"
                  />
                </div>
                <h3 className="info__title">Kto?</h3>
                <p className="info__desc">
                  <strong>15-25 rokov</strong>
                  <br/><br/>
                  ak sa nebojíš zamočiť, zablatiť a odhaľovať svoje slabosti, MEGAKEMPER bude zaručeným hajlajtom tvojho leta!
                </p>
              </div>
              <div className={`info__item animated ${isVisibleInfo ? 'delay-150ms fadeInUp' : 'hidden'}`}>
                <div className="info__img_bg">
                  <FontAwesomeIcon 
                    icon={faCreditCard} 
                    className="info__img"
                  />
                </div>
                <h3 className="info__title">Koľko?</h3>
                <p className="info__desc">
                  <strong>100€ - prvýkrát <br/> 125€ - normálne</strong>
                  <br/><br/>
                  Platba prevodom na účet IBAN: SK66 0900 0000 0005 1195 2302
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="about_us" className="about" ref={RefAbout}>
        <div className="about__img_box">
          <div className="about__bg"></div>
          <div className="about__bg_gradient1"></div>
          <div className="about__bg_gradient2"></div>
          <div className="about__bg_gradient3"></div>
        </div>
        <div className="about__box">
          <div className={`about__content animated ${isVisibleAbout ? 'fadeInLeft' : 'hidden'}`}>
            <h2 className="about__title">O nás</h2>
            <p className="about__text">
              {/* <strong>Kemp organizuje kostol na Hrane z Revúcej.</strong><br/><br/> */}
              Kostol na hrane, organizátor kempu, je kostol pre ľudí, ktorí nechodia do kostola. Slúžime primárne ľuďom v Revúcej, no na našich táboroch túžime prinášať dôležité otázky a témy ľuďom z celého Slovenska (a zahraničia).
              <br/><br/>
              Na tábore budeš vidieť, ako žijeme našu vieru a spoločne budeme premýšľať nad tým, kam vedie naša životná cesta. Budeme sa rozprávať o tom, že každý z nás si v sebe nesie svoju dušu, ktorá je cennejšia než všetky poklady sveta.
            </p>
          </div>
        </div>
      </section>

      <div className="about__diviner"></div>

      <section id="program" className="program">
        <div className="program__img_box">
          <div className="program__bg"></div>
          <div className="program__bg_gradient1"></div>
          <div className="program__bg_gradient2"></div>
          <div className="program__bg_gradient3"></div>
        </div>
        <div className="program__box">
          <h2 className="program__title">Čo u nás zažiješ</h2>
          <div className="program__content">
            <div className="program__relative">
              <div
                onClick={() => handleProgram(1)}
                className={`program__item program__first_item ${selectProgram == 1 ? 'item__active' : ''}`}
              >
                <p className="program__text">
                  <span className="program__text_title">Hry a zábavný program</span><br/>
                </p>
              </div>
              <div className={`program__card_box animated2 ${selectProgram == 1 ? 'openBox' : 'program__hide'}`}
              >
                <img className="program__card_img" src={activity}/>
                <div className="program__card_content">
                  <p className="program__card_title">Hry a zábavný program</p>
                  <p className="program__card_text">Od naháňačiek v lese až po scénky v bufete počas dažďa. Každý rok sa snažíme vymyslieť nové zaujímavé spôsoby ako spolu tráviť čas a vytvoriť si super zážitky. Hry hráme v tímoch, ktoré sa vytvoria vždy v prvé dni tábora. V tomto tíme budeš súťažiť až do konca tábora (až kým nevyhráte).</p>
                </div>
              </div>
            </div>
            <div className="program__relative">
              <div
                onClick={() => handleProgram(2)}
                className={`program__item ${selectProgram == 2 ? 'item__active' : ''}`}
              >
                <p className="program__text">
                  <span className="program__text_title">Crafty a workshopy </span><br/>
                </p>
              </div>
              <div className={`program__card_box animated2 ${selectProgram == 2 ? 'openBox' : 'program__hide'}`}
              >
                <img className="program__card_img" src={crafty}/>
                <div className="program__card_content">
                  <p className="program__card_title">Crafty a workshopy</p>
                  <p className="program__card_text">Prvý víkend nášho tábora si užívame jedinečné workshopy na rôzne témy. Niektoré vedieme my, na niektoré pozývame špeciálnych hostí. Snažíme sa zameriavať na hobby, ktoré robí veľa z nás, ale aj na špecialitky, ktoré zažiješ možno len raz v živote.</p>
                </div>
              </div>
            </div>
            <div className="program__relative">
              <div
                onClick={() => handleProgram(3)}
                className={`program__item ${selectProgram == 3 ? 'item__active' : ''}`}
              >
                <p className="program__text">
                  <span className="program__text_title">Večerný program </span><br/>
                </p>
              </div>
              <div className={`program__card_box animated2 ${selectProgram == 3 ? 'openBox' : 'program__hide'}`}
              >
                <img className="program__card_img" src={vecer}/>
                <div className="program__card_content">
                  <p className="program__card_title">Večerný program</p>
                  <p className="program__card_text">Prečo sme tu? Odkiaľ sme prišli, aký je náš zámer na tomto svete a kam pôjdeme, keď tu skončíme svoju cestu? Keďže sme kresťania, večerné témy vždy nesú posolstvo z Biblie, no zároveň sa ich snažíme pripraviť tak, aby prehovárali do života každého z nás.</p>
                </div>
              </div>
            </div>
            <div className="program__relative">
              <div
                onClick={() => handleProgram(4)}
                className={`program__item ${selectProgram == 4 ? 'item__active' : ''}`}
              >
                <p className="program__text">
                  <span className="program__text_title">Táboráky </span><br/>
                </p>
              </div>
              <div className={`program__card_box animated2 ${selectProgram == 4 ? 'openBox' : 'program__hide'}`}
              >
                <img className="program__card_img" src={taborak}/>
                <div className="program__card_content">
                  <p className="program__card_title">Táboráky</p>
                  <p className="program__card_text">Po večernom programe sa spoločne presúvame zohriať k veľkej vatre. Praskanie ohňa, spievanie piesní, radosť, teplo, a nádherný pocit, že žiješ tu a teraz.</p>
                </div>
              </div>
            </div>
            <div className="program__relative">
              <div
                onClick={() => handleProgram(5)}
                className={`program__item ${selectProgram == 5 ? 'item__active' : ''}`}
              >
                <p className="program__text">
                  <span className="program__text_title">Fyzické výzvy </span><br/>
                </p>
              </div>
              <div className={`program__card_box animated2 ${selectProgram == 5 ? 'openBox' : 'program__hide'}`}
              >
                <img className="program__card_img" src={fyzicke}/>
                <div className="program__card_content">
                  <p className="program__card_title">Fyzické výzvy</p>
                  <p className="program__card_text">Nemusíš mať obrovské svaly a zabehnúť 12-minútovku za 9 minút, aby si tieto výzvy splnil. To, čo potrebuješ najviac, je vytrvalosť a odhodlanie vydržať. Fyzických výziev máme niekoľko - niektoré otestujú tvoju silu, iné výdrž, a iné dokonca odolnosť voči vplyvom počasia.</p>
                </div>
              </div>
            </div>
            <div className="program__relative">
              <div
                onClick={() => handleProgram(6)}
                className={`program__item ${selectProgram == 6 ? 'item__active' : ''}`}
              >
                <p className="program__text">
                  <span className="program__text_title">Duchovné výzvy </span><br/>
                </p>
              </div>
              <div className={`program__card_box animated2 ${selectProgram == 6 ? 'openBox' : 'program__hide'}`}
              >
                <img className="program__card_img" src={duchovne}/>
                <div className="program__card_content">
                  <p className="program__card_title">Duchovné výzvy</p>
                  <p className="program__card_text">Výzvy, ktoré skutočne otestujú tvoju vôľu. Každý rok duchovné výzvy obmieňame a pridávame nové. Veríme, že ak túžime stretnúť Boha, musíme vyjsť ďaleko zo zóny svojho komfortu. Práve toto je našim cieľom v duchovných výzvach. Zabudnúť seba a hľadať toho, ktorý nás stvoril.</p>
                </div>
              </div>
            </div>
            <div className="program__relative">
              <div
                onClick={() => handleProgram(7)}
                className={`program__item ${selectProgram == 7 ? 'item__active' : ''}`}
              >
                <p className="program__text">
                  <span className="program__text_title">Tímové skupinky </span><br/>
                </p>
              </div>
              <div className={`program__card_box animated2 ${selectProgram == 7 ? 'openBox' : 'program__hide'}`}
              >
                <img className="program__card_img" src={skupinky}/>
                <div className="program__card_content">
                  <p className="program__card_title">Tímové skupinky</p>
                  <p className="program__card_text">Každé ráno začíname deň zdieľaním v tíme (až po raňajkách, neboj). Tieto spoločné rána pomáhajú upevniť vzťahy v tíme, dozvedieť sa viac jeden o druhom a spoločne hlbšie uvažovať o tom, čo bolo povedané na večernom programe deň predtým.</p>
                </div>
              </div>
            </div>
            <div className="program__relative">
              <div
                onClick={() => handleProgram(8)}
                className={`program__item ${selectProgram == 8 ? 'item__active' : ''}`}
              >
                <p className="program__text">
                  <span className="program__text_title">Kaplnka </span><br/>
                </p>
              </div>
              <div className={`program__card_box animated2 ${selectProgram == 8 ? 'openBox' : 'program__hide'}`}
              >
                <img className="program__card_img" src={kaplnka}/>
                <div className="program__card_content">
                  <p className="program__card_title">Kaplnka</p>
                  <p className="program__card_text">Na tábore je zväčša okolo 100 ľudí. Uvedomujeme si, že v takom hlučnom a živom prostredí môže byť niekedy náročné sa stíšiť a byť sám so svojimi myšlienkami a modlitbami. Práve na modlitby, stíšenia, chvály slúži naša krásna kaplnka. Na kraji tábora, v tichu lesa, je tento skromný priestor ideálny na stretnutia so Stvoriteľom.</p>
                </div>
              </div>
            </div>
            <div className="program__relative">
              <div
                onClick={() => handleProgram(9)}
                className={`program__item ${selectProgram == 9 ? 'item__active' : ''}`}
              >
                <p className="program__text">
                  <span className="program__text_title">Dobrovoľníctvo </span><br/>
                </p>
              </div>
              <div className={`program__card_box program__last animated2 ${selectProgram == 9 ? 'openBox' : 'program__hide'}`}
              >
                <img className="program__card_img" src={dobrovolnictvo}/>
                <div className="program__card_content">
                  <p className="program__card_title">Dobrovoľníctvo</p>
                  <p className="program__card_text">Služba druhým je v našich kruhoch dôležitá. Často si pripomíname, že môžeme mať mnoho talentov, no podstatné je, či sme ochotní sa o ne podeliť so svetom. Na tábore slúžime jeden druhému, kuchyni, chalanom technikom, chalanom organizátorom, a tiež niekedy navštívime Revúcu, aby sme slúžili ľuďom v meste.</p>
                </div>
              </div>
            </div>
            <div className="program__relative">
              <div
                onClick={() => handleProgram(10)}
                className={`program__item ${selectProgram == 10 ? 'item__active' : ''}`}
              >
                <p className="program__text">
                  <span className="program__text_title">Bufet </span><br/>
                </p>
              </div>
              <div className={`program__card_box program__last animated2 ${selectProgram == 10 ? 'openBox' : 'program__hide'}`}
              >
                <img className="program__card_img" src={bufet}/>
                <div className="program__card_content">
                  <p className="program__card_title">Bufet</p>
                  <p className="program__card_text">Tábor bez bufetu si už ani nevieme predstaviť. Je to miesto, kde môžeš s kamošmi pochillovať, zahrať si hry, odpovedať na zaujímavé otázky, dať si niečo sladké pod zub (v stane by ti to zožrali myši), alebo si dať poobedného šlofíka. Každý rok sa snažíme robiť bufet krajší, útulnejší a chutnejší.</p>
                </div>
              </div>
            </div>
            <div className="program__relative">
              <div
                onClick={() => handleProgram(11)}
                className={`program__item program__last_item ${selectProgram == 11 ? 'item__active' : ''}`}
              >
                <p className="program__text">
                  <span className="program__text_title ">Nečakané prekvapenia! </span><br/>
                </p>
              </div>
              <div className={`program__card_box program__last animated2 ${selectProgram == 11 ? 'program__last_item openBox' : 'program__hide'}`}
              >
                <img className="program__card_img program__last_item" src={special}/>
                <div className="program__card_content">
                  <p className="program__card_title">Nečakané prekvapenia!</p>
                  <p className="program__card_text">Každý rok ťa okrem spomínaného programu na tábore prekvapíme niečím spešl!<br/>
                  Máš sa na čo tešiť, hehe!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="openday" className="openday" ref={RefOpenDay}>
        <div className="openday__box">
          <div className={`openday__content animated ${isVisibleOpenDay ? 'fadeInLeft' : 'hidden'}`}>
            <h2 className="openday__title">Večer otvorených dverí</h2>
            <p className="openday__text">V <strong>stredu 14.08. o 19:00</strong> bude výnimočný večer, keď sa dvere tábora otvoria pre verejnosť. Tvoji rodičia, priatelia, susedia aj učiteľky budú pozvaní na jeden večer zažiť táborovú atmošku.</p>
          </div>
          <img className="openday__img" src={openday}/>
        </div>
      </section>

      <section id="specialdays" className="days" ref={RefSpecialDay}>
        <div className="days__box">
          <h1 className="days__title">Tématické dni</h1>
          <div className="days__content_img">
            <div className="days__item">
              <img className={`days__img animated ${isVisibleSpecialDay ? 'fadeInUp' : 'hidden'}`} src={western}/>
            </div>
            <div className="days__item">
              <img className={`days__img animated ${isVisibleSpecialDay ? 'delay-50ms fadeInUp' : 'hidden'}`} src={circus}/>
            </div>
            <div className="days__item">
              <img className={`days__img animated ${isVisibleSpecialDay ? 'delay-100ms fadeInUp' : 'hidden'}`} src={sherlock}/>
            </div>
          </div>
          <div className="days__content">
            <div className="days__item">
              <h2 className="days__item_title">Divoký západ</h2>
              <p className="days__item_text">Kovboji, banditi, indiáni. Obuj si čižmy, nasaď si klobúk a priprav sa na drsné prestrelky na divokom západe</p>
            </div>
            <div className="days__item">
              <h2 className="days__item_title">Cirkus</h2>
              <p className="days__item_text">Uvoľni svojho vnútorného klauna. Pozývame ťa na nezabudnutelné predstavenie! Staň sa pravím cirkusantom!</p>
            </div>
            <div className="days__item">
              <h2 className="days__item_title">Sherlock</h2>
              <p className="days__item_text">Pátraj po stopách, rieš záhady a rozlúšť tajomstvá, ako pravý detektív! Zober lupu, pero a vyrieš záhady Zdychavskej doliny!</p>
            </div>
          </div>
        </div>
      </section>

      <section id="packing" className="packing">
        <div className="packing__bg"></div>
        <div className="packing__box">
          <h2 className="packing__title">Čo si nezabudni doniesť!</h2>
          <div className="packing__content">
            <div className="packing__item">
              <h3 className="packing__item_title">kempingová výbava</h3>
              <p className="packing__list">
                1. Stan a kolíky<br/>
                2. Teplý spacák<br/>
                3. Karimatka<br/>
                4. Vankúš<br/>
                5. Batérka / Čelovka<br/>
                6. Deka k táboráku<br/>
                7. Ruksak v prípade výletu<br/>
                8. Príbor, ešus, pohár<br/>
              </p>
            </div>
            <div className="packing__item">
              <h3 className="packing__item_title">oblečenie do každého počasia</h3>
              <p className="packing__list">
                1. Staré oblečenie (ktoré sa môže zničiť/zašpiniť)<br/>
                2. Tričká (dlhé, krátke)<br/>
                3. Nohavice, Kraťasy<br/>
                4. Spodné prádlo<br/>
                5. veeľa veľa ponožiek<br/>
                6. Teplá bunda<br/>
                7. Plavky<br/>
                8. 2x Topánky, šlapky<br/>
                9. Čiapka ,šiltovka, slnečné okuliare
              </p>
            </div>
            <div className="packing__item">
              <h3 className="packing__item_title">špeci veci</h3>
              <p className="packing__list">
                1. Repelent<br/>
                2. Krém na opaľovanie<br/>
                3. Hygienické potreby<br/>
                4. Zápisník, pero<br/>
                5. lieky (v prípade, že nejaké užívaš)<br/>
                6. kartu o zdravotnom poistení<br/>
                7. pekné oblečenie (fancy oblečko na špeciálny večer)<br/>
                8. funky veci na tvorbu kostýmov<br/>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="photos" className="photos">
        <div className="photos__box">
          <div className="photos__flex">
            <img className="photos__img" src={photo_1}/>
            <img className="photos__img" src={photo_2}/>
          </div>
          <div className="photos__flex">
            <img className="photos__img" src={photo_3}/>
            <img className="photos__img" src={photo_4}/>
          </div>
        </div>
        <div className="photos__box">
          <div className="photos__flex">
            <img className="photos__img" src={photo_5}/>
            <img className="photos__img" src={photo_6}/>
          </div>
          <div className="photos__flex">
            <img className="photos__img" src={photo_7}/>
            <img className="photos__img" src={photo_8}/>
          </div>
        </div>
        <div className="photos__box">
          <div className="photos__flex">
            <img className="photos__img" src={photo_9}/>
            <img className="photos__img" src={photo_10}/>
          </div>
          <div className="photos__flex">
            <img id="registration" className="photos__img" src={photo_11}/>
            <img className="photos__img" src={photo_12}/>
          </div>
        </div>
      </section>

      <div className='regcard'>
        <div className='regcard__box'>
          <h2 className="regcard__title">Registrácia</h2>
          <p className='regcard__text'>Po úspešnej registrácii obdržíte email o potvrdení registrácie s platobnými údajmi.</p>
        </div>
      </div>

      <section className="reg">
        {!isRegistered &&(
        <div className="reg__type_box">
          <div className={`reg__type ${isCamperRegistrationActive ? 'reg__active' : ''} reg__border_1`} onClick={toggleRegistrationType1}>
            <h2 className={`reg__type_title ${isCamperRegistrationActive ? 'reg__active' : ''}`}>Registrácia Táborníka</h2>
          </div>
          <div className={`reg__type ${isServantRegistrationActive ? 'reg__active' : ''} reg__border_2`} onClick={toggleRegistrationType2}>
            <h2 className={`reg__type_title ${isServantRegistrationActive ? 'reg__active' : ''}`}>Registrácia pre realizačný tím</h2>
          </div>
        </div>
        )}
          {!isRegistered && isCamperRegistrationActive &&(
            <div className="reg__campers">
              <form className="reg__form" onSubmit={handleSubmitCampers}> 
                <div className="reg__form_box">
                  <div className="reg__form_half">
                    <div>
                      <p className="reg__form_label">Meno <span className="reg__form_red">*</span></p>
                      <input 
                        className="reg__form_input"
                        name="first_name"
                        type="text"
                        autoComplete="given-name"
                        required="required"
                        value={formDataCampers.first_name}
                        onChange={handleChangeCampers}
                      ></input>
                    </div>
                    <div>
                      <p className="reg__form_label">Priezvisko <span className="reg__form_red">*</span></p>
                      <input 
                        className="reg__form_input"
                        name="surname"
                        type="text"
                        autoComplete="family-name"
                        required="required"
                        value={formDataCampers.surname}
                        onChange={handleChangeCampers}
                      ></input>
                    </div>
                    <div>
                      <p className="reg__form_label">Adresa <span className="reg__form_red">*</span></p>
                      <input 
                        className="reg__form_input"
                        name="address"
                        type="text"
                        autoComplete="street-address"
                        required="required"
                        value={formDataCampers.address}
                        onChange={handleChangeCampers}
                      ></input>
                    </div>
                    <div>
                      <p className="reg__form_label">Mesto <span className="reg__form_red">*</span></p>
                      <input 
                        className="reg__form_input"
                        name="city"
                        type="text"
                        autoComplete="address-level2"
                        required="required"
                        value={formDataCampers.city}
                        onChange={handleChangeCampers}
                      ></input>
                    </div>
                  </div>
                  <div className="reg__form_half">
                    <div>
                      <p className="reg__form_label">Email <span className="reg__form_red">*</span></p>
                      <input
                        className="reg__form_input"
                        name="email"
                        type="email" 
                        autoComplete="email" 
                        required="required"
                        value={formDataCampers.email}
                        onChange={handleChangeCampers}
                      ></input>
                    </div>
                    <div>
                      <p className="reg__form_label">Dátum narodenia <span className="reg__form_red">*</span></p>
                      <input 
                        className="reg__form_input reg_pad"
                        name="birthdate"
                        type="date"
                        autoComplete="bday"
                        required="required"
                        value={formDataCampers.birthdate}
                        onChange={handleChangeCampers}
                      ></input>
                    </div>
                    <div>
                      <p className="reg__form_label">Tel. číslo <span className="reg__form_red">*</span></p>
                      <input
                        className="reg__form_input" 
                        name="number" 
                        type="number" 
                        autoComplete="tel" 
                        required="required"
                        value={formDataCampers.number}
                        onChange={handleChangeCampers}
                      ></input>
                    </div>
                    <div>
                      <p className="reg__form_label">Tel. číslo rodiča <span className="reg__form_red">*</span></p>
                      <input 
                        className="reg__form_input" 
                        name="number_parent" 
                        type="number" 
                        autoComplete="tel" 
                        required="required"
                        value={formDataCampers.number_parent}
                        onChange={handleChangeCampers}
                      ></input>
                    </div>
                  </div>
                </div>
                <hr className="hr"></hr>
                <div className="reg__form_box">
                  <div className="reg__form_half">
                    <div>
                      <p className="reg__form_label">Choroby, diéty, obmedzenia </p>
                      <textarea
                        placeholder="Ak nebudeš na tábore celý čas, napíš to sem!"
                        className="reg__form_input"
                        name="message"
                        value={formDataCampers.message}
                        onChange={handleChangeCampers}
                      ></textarea>
                    </div>
                    <div>
                      <p className="reg__form_label">Veľkosť trička <span className="reg__form_red">*</span></p>
                      <select 
                        className="reg__form_input"
                        name="tshirt"
                        required="required"
                        value={formDataCampers.tshirt}
                        onChange={handleChangeCampers}
                      >
                        <option value="XS">XS</option>
                        <option value="S">S</option>
                        <option value="M">M</option>
                        <option value="L">L</option>
                        <option value="XL">XL</option>
                        <option value="XXL">XXL</option>
                      </select>
                    </div>
                  </div>
                  <div className="reg__form_half">
                    <div>
                      <p className="reg__form_label">Moje očakávania od tábora </p>
                      <textarea 
                        className="reg__form_input"
                        name="expectations"
                        value={formDataCampers.expectations}
                        onChange={handleChangeCampers}
                      ></textarea>
                    </div>
                    <div>
                      <p className="reg__form_label">Som na tábore prvýkrát <span className="reg__form_red">*</span></p>
                      <select 
                        className="reg__form_input" 
                        name="first_time" 
                        required="required"
                        value={formDataCampers.first_time}
                        onChange={handleChangeCampers}
                      >
                        <option value="nie">Nie</option>
                        <option value="ano">Áno</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="reg__submit_box">
                  <button type="submit" className="reg__submit">Registrovať</button>
                </div>
              </form>
            </div>
          )}
          {!isRegistered &&isServantRegistrationActive &&(
            <div className="reg__servants">
              <form className="reg__form" onSubmit={handleSubmitServant}> 
                <p className="reg__info">Tento typ registrácie je pre realizačný a organizačný tím. Ak si nebol oslovený do realizačného tímu, prosím registruj sa ako táborník. Ak by si mal záujem byť súčasťnou realizačného tímu, daj nám o tom prosím vedieť.</p>
                <div className="reg__form_box">
                  <div className="reg__form_half">
                    <div>
                      <p className="reg__form_label">Typ služby <span className="reg__form_red">*</span></p>
                      <select 
                        className="reg__form_input"
                        name="type"
                        required="required"
                        value={formDataServant.type}
                        onChange={handleChangeServant}
                      >
                        <option value="Programový tím">Programový tím</option>
                        <option value="Duchovný tím">Duchovný tím</option>
                        <option value="Kuchyňa">Kuchyňa</option>
                        <option value="Bufet">Bufet</option>
                        <option value="Foto/kamera, strih">Foto/kamera, strih</option>
                      </select>
                    </div>
                  </div>
                  <div className="reg__form_half">
                  </div>
                </div>
                <hr className="hr"></hr>
                <div className="reg__form_box">
                  <div className="reg__form_half">
                    <div>
                      <p className="reg__form_label">Meno <span className="reg__form_red">*</span></p>
                      <input 
                        className="reg__form_input"
                        name="first_name"
                        type="text"
                        autoComplete="given-name"
                        required="required"
                        value={formDataServant.first_name}
                        onChange={handleChangeServant}
                      ></input>
                    </div>
                    <div>
                      <p className="reg__form_label">Priezvisko <span className="reg__form_red">*</span></p>
                      <input 
                        className="reg__form_input"
                        name="surname"
                        type="text"
                        autoComplete="family-name"
                        required="required"
                        value={formDataServant.surname}
                        onChange={handleChangeServant}
                      ></input>
                    </div>
                    <div>
                      <p className="reg__form_label">Adresa <span className="reg__form_red">*</span></p>
                      <input 
                        className="reg__form_input"
                        name="address"
                        type="text"
                        autoComplete="street-address"
                        required="required"
                        value={formDataServant.address}
                        onChange={handleChangeServant}
                      ></input>
                    </div>
                    <div>
                      <p className="reg__form_label">Mesto <span className="reg__form_red">*</span></p>
                      <input 
                        className="reg__form_input"
                        name="city"
                        type="text"
                        autoComplete="address-level2"
                        required="required"
                        value={formDataServant.city}
                        onChange={handleChangeServant}
                      ></input>
                    </div>
                  </div>
                  <div className="reg__form_half">
                    <div>
                      <p className="reg__form_label">Email <span className="reg__form_red">*</span></p>
                      <input
                        className="reg__form_input"
                        name="email"
                        type="email" 
                        autoComplete="email" 
                        required="required"
                        value={formDataServant.email}
                        onChange={handleChangeServant}
                      ></input>
                    </div>
                    <div>
                      <p className="reg__form_label">Dátum narodenia <span className="reg__form_red">*</span></p>
                      <input 
                        className="reg__form_input reg_pad"
                        name="birthdate"
                        type="date"
                        autoComplete="bday"
                        required="required"
                        value={formDataServant.birthdate}
                        onChange={handleChangeServant}
                      ></input>
                    </div>
                    <div>
                      <p className="reg__form_label">Tel. číslo <span className="reg__form_red">*</span></p>
                      <input
                        className="reg__form_input" 
                        name="number" 
                        type="number" 
                        autoComplete="tel" 
                        required="required"
                        value={formDataServant.number}
                        onChange={handleChangeServant}
                      ></input>
                    </div>
                    <div>
                      <p className="reg__form_label">Tel. číslo rodiča <span className="reg__form_red">*</span></p>
                      <input 
                        className="reg__form_input" 
                        name="number_parent" 
                        type="number" 
                        autoComplete="tel" 
                        required="required"
                        value={formDataServant.number_parent}
                        onChange={handleChangeServant}
                      ></input>
                    </div>
                  </div>
                </div>
                <hr className="hr"></hr>
                <div className="reg__form_box">
                  <div className="reg__form_half">
                    <div>
                      <p className="reg__form_label">Choroby, diéty, obmedzenia </p>
                      <textarea
                        placeholder="Ak nebudeš na tábore celý čas, napíš to sem!"
                        className="reg__form_input"
                        name="message"
                        value={formDataServant.message}
                        onChange={handleChangeServant}
                      ></textarea>
                    </div>
                    <div>
                      <p className="reg__form_label">Veľkosť trička <span className="reg__form_red">*</span></p>
                      <select 
                        className="reg__form_input"
                        name="tshirt"
                        required="required"
                        value={formDataServant.tshirt}
                        onChange={handleChangeServant}
                      >
                        <option value="XS">XS</option>
                        <option value="S">S</option>
                        <option value="M">M</option>
                        <option value="L">L</option>
                        <option value="XL">XL</option>
                        <option value="XXL">XXL</option>
                      </select>
                    </div>
                  </div>
                  <div className="reg__form_half">
                    <div>
                      <p className="reg__form_label">Moje očakávania od tábora </p>
                      <textarea 
                        className="reg__form_input"
                        name="expectations"
                        value={formDataServant.expectations}
                        onChange={handleChangeServant}
                      ></textarea>
                    </div>
                    <div>
                      <p className="reg__form_label">Som na tábore prvýkrát <span className="reg__form_red">*</span></p>
                      <select 
                        className="reg__form_input" 
                        name="first_time" 
                        required="required"
                        value={formDataServant.first_time}
                        onChange={handleChangeServant}
                      >
                        <option value="nie">Nie</option>
                        <option value="ano">Áno</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="reg__submit_box">
                  <button type="submit" className="reg__submit">Registrovať</button>
                </div>
              </form>
            </div>
          )}
          {isRegistered && isSuccess &&(
            <div className="reg__success">
              <h2 className="reg__type_title success">Registrácia úspešná</h2>
              <p className="reg__form_label">Na email Vám boli odoslané údaje o platbe.</p>
            </div>
          )}
          {isRegistered && !isSuccess &&(
            <div className="reg__success">
              <h2 className="reg__type_title error">Registrácia neúspešná</h2>
              <p className="reg__form_label">Nastala chyba pri odosielaní emailu.</p>
            </div>
          )}
      </section>

      <footer id="contact" className="footer">
        <div className="footer__box">
          <h2 className="footer__title">Ak máš otázky, neváhaj nás kontaktovať</h2>
          <div className="footer__kontakt">
            <div className="footer__card">
              <FontAwesomeIcon 
                icon={faLocationDot}
                className="footer__card_img"
              />
              <h3 className="footer__card_title">Adresa</h3>
              <p className="footer__card_text">Muránska Zdychava<br/>(karafová)</p>
            </div>
            <div className="footer__card">
              <FontAwesomeIcon 
                icon={faMobileScreenButton}
                className="footer__card_img"
              />
              <h3 className="footer__card_title">Mobil</h3>
              <p className="footer__card_text">Richard Nagypál<br/>+421 911 868 811</p>
            </div>
            <div className="footer__card">
              <FontAwesomeIcon 
                icon={faEnvelope}
                className="footer__card_img"
              />
              <h3 className="footer__card_title">Email</h3>
              <p className="footer__card_text">kostolnahrane@gmail.com<br/><br/></p>
            </div>
          </div>
          <div className="footer__social">
            <a className="footer__social_card" target="_blank" href="https://www.facebook.com/kostolnahrane">
              <FontAwesomeIcon 
                icon={faSquareFacebook}
                className="footer__social_img"
              />
            </a>
            <a className="footer__social_card" target="_blank" href="https://www.instagram.com/kostolnahrane/">
              <FontAwesomeIcon 
                icon={faInstagram}
                className="footer__social_img"
              />
            </a>
            <a className="footer__social_card" target="_blank" href="https://www.youtube.com/@kostolnahrane4453">
              <FontAwesomeIcon 
                icon={faYoutube}
                className="footer__social_img"
              />
            </a>
          </div>
        </div>
        <div className="copyright__box">
          <p className="copyright__text">
            <a className="copyright__link">Ochrana osobných údajov</a> | <a className="copyright__link">Zásady používania súborov cookie</a>
            <br/>
            Copyright 2024 © Megakemper. Všetky práva vyhradené
          </p>
        </div>
      </footer>

    </div>
  );
}
